import React, { useState, useEffect, useRef } from 'react';
import './App.css';

const notes = [
  "Do",
  "Do#",
  "Re",
  "Re#",
  "Mi",
  "Fa",
  "Fa#",
  "Sol",
  "Sol#",
  "La",
  "La#",
  "Si",
];

const scaleQualities = ["Major", "Minor", "Pent Maj", "Pent Min", "Gypsy", "Blues", "Haw", "Jap", "Egy", "Harm Min"];
const chordQualities = ["Major", "Minor", "Sus2", "Sus4", "Maj7", "Aug", "Dim", "Dim7", "m7b5", "Min7", "mM7", "Dom7", "Aug7", "Add9", "Add4", "Add6"];

const chordIntervals = {
  Major: [4, 3],
  Minor: [3, 4],
  Sus2: [2, 5],
  Sus4: [5, 2],
  Maj7: [4, 3, 4],
  Aug: [4, 4],
  Dim: [3, 3],
  Dim7: [3, 3, 3],
  m7b5: [3, 3, 4],
  Min7: [3, 4, 3],
  mM7: [3, 4, 4],
  Dom7: [4, 3, 3],
  Aug7: [4, 4, 2],
  Add9: [4, 3, 7],
  Add4: [4, 1, 2],
  Add6: [4, 3, 2],
};

const scaleIntervals = {
  "Major": [2, 2, 1, 2, 2, 2, 1],
  "Minor": [2, 1, 2, 2, 1, 2, 2],
  "Pent Maj": [2, 2, 3, 2, 3],
  "Pent Min": [3, 2, 2, 3, 2],
  "Gypsy": [1, 3, 1, 2, 1, 3, 1],
  "Blues": [3, 2, 1, 1, 3, 2],
  "Haw": [2, 1, 2, 2, 2, 2, 1],
  "Jap": [1, 4, 2, 3, 2],
  "Egy": [1, 3, 1, 2, 1, 2, 2],
  "Harm Min": [2, 1, 2, 2, 1, 3, 1]
};

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const getIndexInOctave = (note) => {
  return notes.indexOf(note);
};

const generateScale = (rootNote, quality) => {
  let scale = [rootNote];
  let intervals = scaleIntervals[quality];
  let currentNote = rootNote;

  intervals.forEach((interval) => {
    currentNote = getNoteAtInterval(currentNote, interval);
    scale.push(currentNote);
  });

  return scale;
};

const getNoteAtInterval = (startNote, interval) => {
  let startIndex = getIndexInOctave(startNote);
  let targetIndex = (startIndex + interval) % notes.length;
  return notes[targetIndex];
};

const generateChord = (rootNote, quality) => {
  let chord = [rootNote];
  let intervals = chordIntervals[quality];
  let currentNote = rootNote;

  intervals.forEach((interval) => {
    currentNote = getNoteAtInterval(currentNote, interval);
    chord.push(currentNote);
  });

  return chord;
};

const isChordCompatibleWithScale = (chord, scale, generatedChords, generatedScales) => {
  if (!generatedChords[chord] || !generatedScales[scale]) {
    return false;
  }

  return generatedChords[chord].every((note) =>
    generatedScales[scale].includes(note)
  );
};

const noteToMidi = {
  "Do": "C4",
  "Do#": "C#4",
  "Re": "D4",
  "Re#": "D#4",
  "Mi": "E4",
  "Fa": "F4",
  "Fa#": "F#4",
  "Sol": "G4",
  "Sol#": "G#4",
  "La": "A4",
  "La#": "A#4",
  "Si": "B4",
};

/**
 * Obtiene las incompatibilidades entre acordes y escalas.
 *
 * @template {string[]} T Colección de acordes o escalas.
 * @template {string[]} U Colección de acordes o escalas.
 *
 * @param {T} a Colección de acordes o escalas.
 * @param {U} b La otra colección no dada para a.
 * @param {'chord'|'scale'} aIs Identifica qué colección se da para a.
 * @param {'some'|'every'} op La función de comparación para evaluar cómo se deben unir los booleanos de la colección.
 * @param {Object} generatedChords Acordes pre-generados.
 * @param {Object} generatedScales Escalas pre-generadas.
 * @return {T} Colección filtrada a que son incompatibles con b.
 */
const getIncompatibles = (a, b, aIs, op, generatedChords, generatedScales) =>
  a.filter((aValue) =>
    b[op](
      (bValue) =>
        !isChordCompatibleWithScale(
          aIs === "chord" ? aValue : bValue,
          aIs === "chord" ? bValue : aValue,
          generatedChords,
          generatedScales
        )
    )
  );

// Función para convertir el nombre del acorde en una ruta de archivo de sonido
const getSoundFilePath = (chordName) => {
  return `/Chords/${chordName.replace(/\s+/g, '-').replace(/#/g, 'S')}.mp3`;
};

const getScaleSoundFilePath = (scaleName) => {
  return `/Scales/${scaleName.replace(/\s+/g, '-').replace(/#/g, 'S')}.mp3`;
};

// Función para reproducir el sonido de una escala
const playScaleSound = (scale) => {
  const soundFile = getScaleSoundFilePath(scale);
  const audio = new Audio(soundFile);
  audio.play();
};

const App = () => {
  const [selectedChords, setSelectedChords] = useState([]);
  const [selectedScales, setSelectedScales] = useState([]);

  const [draggedChord, setDraggedChord] = useState(null);
  const [droppedChords, setDroppedChords] = useState([]);

  const [playBarPosition, setPlayBarPosition] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);

  const [contextMenu, setContextMenu] = useState({
    mouseX: null,
    mouseY: null,
    visible: false,
  });

  const [selectedNote, setSelectedNote] = useState({ name: null, notes: '' });

  const [saveMenu, setSaveMenu] = useState({ visible: false, x: 0, y: 0 });

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const [theme, setTheme] = useState('dark');

  const [notationType, setNotationType] = useState('DoReMi');

  const [isContactPopupVisible, setIsContactPopupVisible] = useState(false);

  const [bpm, setBpm] = useState(70);

  const [lastPlayedAudio, setLastPlayedAudio] = useState(null);

  const [activeTab, setActiveTab] = useState('Tutorial');

  const [guitarChordImages, setGuitarChordImages] = useState([]);
  const [guitarChordsVisible, setGuitarChordsVisible] = useState(false);

  const [pianoChordImages, setPianoChordImages] = useState([]);
  const [pianoChordsVisible, setPianoChordsVisible] = useState(false);

  const [loading, setLoading] = useState(true);

  const [generatedChords, setGeneratedChords] = useState({});
  const [generatedScales, setGeneratedScales] = useState({});

  const menuIconRef = useRef(null);

  useEffect(() => {
    const chords = {};
    notes.forEach((note) => {
      chordQualities.forEach((quality) => {
        let chordName = `${note} ${quality}`;
        chords[chordName] = generateChord(note, quality);
      });
    });
    setGeneratedChords(chords);

    const scales = {};
    notes.forEach((note) => {
      scaleQualities.forEach((quality) => {
        let scaleName = `${note} ${quality}`;
        scales[scaleName] = generateScale(note, quality);
      });
    });
    setGeneratedScales(scales);
  }, []);

  const playChordSound = (chord) => {
    if (lastPlayedAudio) {
      lastPlayedAudio.pause();
      lastPlayedAudio.currentTime = 0;
    }

    const soundFile = getSoundFilePath(chord);
    const audio = new Audio(soundFile);
    audio.play();

    setLastPlayedAudio(audio);
  };

  const openContactPopup = () => {
    setIsContactPopupVisible(true);
  };

  const closeContactPopup = () => {
    setIsContactPopupVisible(false);
  };

  const mapNotation = (note) => {
    const mapping = {
      'Do': 'C', 'Do#': 'C#', 'Re': 'D', 'Re#': 'D#', 'Mi': 'E',
      'Fa': 'F', 'Fa#': 'F#', 'Sol': 'G', 'Sol#': 'G#', 'La': 'A',
      'La#': 'A#', 'Si': 'B',
      'C': 'Do', 'C#': 'Do#', 'D': 'Re', 'D#': 'Re#', 'E': 'Mi',
      'F': 'Fa', 'F#': 'Fa#', 'G': 'Sol', 'G#': 'Sol#', 'A': 'La',
      'A#': 'La#', 'B': 'Si'
    };
    return mapping[note] || note;
  };

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
    document.body.className = newTheme === 'dark' ? '' : 'light-mode';
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme') || 'dark';
    setTheme(currentTheme);
    document.body.className = currentTheme === 'dark' ? '' : 'light-mode';
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const changeTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsMenuVisible(!isMenuVisible);
  };  

  const handleDocumentClick = (e) => {
    if (isMenuVisible && !menuIconRef.current.contains(e.target)) {
      setIsMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isMenuVisible]);

  const addToBar = (displayChordName) => {
    let internalChordName;
    let displayName;

    if (notationType === 'DoReMi') {
      internalChordName = displayChordName;
      displayName = convertToDisplayNotation(displayChordName, 'CDE');
    } else {
      internalChordName = convertToInternalNotation(displayChordName);
      displayName = displayChordName;
    }

    setDroppedChords(prevChords => [
      ...prevChords,
      { internalName: internalChordName, displayName: displayName }
    ]);
  };

  const handleRightClick = (e, combination, type) => {
    e.preventDefault();
    let notesToShow;

    if (type === "chord" && generatedChords.hasOwnProperty(combination)) {
      notesToShow = generatedChords[combination];
    } else if (type === "scale" && generatedScales.hasOwnProperty(combination)) {
      notesToShow = generatedScales[combination];
    } else {
      notesToShow = ['N/A'];
    }

    const playAction = type === "chord" ? () => playChordSound( combination) : () => playScaleSound(combination);

    setSelectedNote({
      name: combination,
      notes: notesToShow.join(', '),
      play: playAction,
      addToBar: addToBar,
      type: type
    });

    const menuWidth = 150; // Ajusta según el ancho real de tu menú
    const menuHeight = 120; // Ajusta según la altura real de tu menú

    let posX = e.clientX - 2;
    let posY = e.clientY - 4;

    if (posX + menuWidth > window.innerWidth) {
      posX = window.innerWidth - menuWidth - 10;
    }

    if (posY + menuHeight > window.innerHeight) {
      posY = window.innerHeight - menuHeight - 10;
    }

    setContextMenu({
      mouseX: posX,
      mouseY: posY,
      visible: true,
    });
  };

  const closeContextMenu = () => {
    setContextMenu({
      ...contextMenu,
      visible: false,
    });
  };

  useEffect(() => {
    if (activeTab !== 'Tutorial') {
      const closeContextMenuIfClickedOutside = (event) => {
        closeContextMenu();
      };

      window.addEventListener('click', closeContextMenuIfClickedOutside);
      return () => {
        window.removeEventListener('click', closeContextMenuIfClickedOutside);
      };
    }
  }, [activeTab]);

  const preloadAudioFiles = (chords) => {
    const audioFiles = chords.map(chord => {
      const audio = new Audio(getSoundFilePath(chord));
      return new Promise((resolve) => {
        audio.addEventListener('canplaythrough', () => resolve(audio), { once: true });
      });
    });

    return Promise.all(audioFiles);
  };

  const handlePlayClick = () => {
    if (!isPlaying && droppedChords.length > 0) {
      setIsPlaying(true);
      setPlayBarPosition(0);

      preloadAudioFiles(droppedChords.map(chord => chord.internalName)).then(audioFiles => {
        audioFiles.forEach((audio, index) => {
          const waitTime = 60000 / bpm;

          setTimeout(() => {
            audio.play();
            setPlayBarPosition(index);

            if (index === droppedChords.length - 1) {
              setTimeout(() => {
                setIsPlaying(false);
                setPlayBarPosition(-1);
              }, waitTime);
            }
          }, waitTime * index);
        });
      });
    }
  };

  const handleDropOnTrashBin = (e) => {
    e.preventDefault();
    const newDroppedChords = droppedChords.filter(chordObj => chordObj.internalName !== draggedChord);
    setDroppedChords(newDroppedChords);

    updateVisualizationsPostRemoval(newDroppedChords);
  };

  const updateVisualizationsPostRemoval = (updatedDroppedChords) => {
    if (guitarChordsVisible) {
      setGuitarChordImages(generateChordImages(updatedDroppedChords, 'guitar'));
    }
    if (pianoChordsVisible) {
      setPianoChordImages(generateChordImages(updatedDroppedChords, 'piano'));
    }
  };

  const generateChordImages = (chords, instrument) => {
    return chords.map(chordObj => {
      const chordFileName = chordObj.internalName.replace(/\s+/g, '-').replace(/#/g, 'S') + '.png';
      return `/Chords-${instrument}/${chordFileName}`;
    });
  };

  const calculateSegmentBasedDropIndex = (dropX) => {
    const dropBar = document.querySelector('.drop-bar');
    const dropBarRect = dropBar.getBoundingClientRect();
    const relativeX = dropX - dropBarRect.left;

    const numSegments = droppedChords.length + 1;
    const segmentWidth = dropBarRect.width / numSegments;

    let segmentIndex = Math.floor(relativeX / segmentWidth);
    if (relativeX === dropBarRect.width) {
      segmentIndex = droppedChords.length;
    }

    segmentIndex = Math.max(0, Math.min(segmentIndex, droppedChords.length));
    updateDropHighlight(-1);
    return segmentIndex;
  };

  const handleDragStartOnBar = (e, chord) => {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', chord);
    setDraggedChord(chord);
  };

  const handleDropOnBar = (e) => {
    e.preventDefault();
    const draggedChordInternalName = e.dataTransfer.getData('text');

    const draggedChordObj = droppedChords.find(chord => chord.internalName === draggedChordInternalName);

    if (!draggedChordObj) return;

    const newDroppedChords = droppedChords.filter(chord => chord.internalName !== draggedChordInternalName);

    const dropIndex = calculateSegmentBasedDropIndex(e.clientX);

    newDroppedChords.splice(dropIndex, 0, draggedChordObj);

    setDroppedChords(newDroppedChords);

    updateDropHighlight(-1);
  };

  const updateDropHighlight = (dropIndex) => {
    const existingHighlights = document.querySelectorAll('.drop-highlight');
    existingHighlights.forEach(el => el.classList.remove('drop-highlight'));

    if (dropIndex === -1) {
      return;
    }

    const dropBar = document.querySelector('.drop-bar');
    const chords = dropBar.children;

    if (dropIndex < chords.length) {
      chords[dropIndex].classList.add('drop-highlight');
    } else if (chords.length > 0) {
      chords[chords.length - 1].classList.add('drop-highlight');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    const dropIndex = calculateSegmentBasedDropIndex(e.clientX);

    updateDropHighlight(dropIndex);
  };

  const generateDroppedChords = () => {
    return droppedChords.map((chordObj, index) => (
      <div
        key={index}
        className={`dropped-chord ${isPlaying && index === playBarPosition && playBarPosition !== -1 ? 'active-chord' : ''}`}
        draggable
        onDragStart={(e) => handleDragStartOnBar(e, chordObj.internalName)}
        onDrop={handleDropOnBar}
        onDragOver={handleDragOver}
      >
        {chordObj.displayName}
      </div>
    ));
  };

  const handleDragStart = (e, chord) => {
    if (isMobileDevice()) {
      e.preventDefault();
    } else {
      e.dataTransfer.setData('text/plain', chord);
      e.dataTransfer.setData('isNewChord', true);
      setDraggedChord(chord);
    }
  };

  const convertToInternalNotation = (chordName) => {
    const mapping = {
      'C': 'Do', 'C#': 'Do#', 'D': 'Re', 'D#': 'Re#', 'E': 'Mi',
      'F': 'Fa', 'F#': 'Fa#', 'G': 'Sol', 'G#': 'Sol#', 'A': 'La',
      'A#': 'La#', 'B': 'Si'
    };

    const parts = chordName.split(" ");
    if (parts.length > 1) {
      const [note, ...qualityParts] = parts;
      const quality = qualityParts.join(" ");
      const internalNote = mapping[note] || note;
      return `${internalNote} ${quality}`;
    }

    return chordName;
  };

  const convertToDisplayNotation = (chordName, targetNotation) => {
    const toDoReMi = {
      'C': 'Do', 'C#': 'Do#', 'D': 'Re', 'D#': 'D#', 'E': 'Mi',
      'F': 'Fa', 'F#': 'Fa#', 'G': 'Sol', 'G#': 'G#', 'A': 'La',
      'A#': 'La#', 'B': 'Si'
    };
    const toCDE = {
      'Do': 'C', 'Do#': 'C#', 'Re': 'D', 'Re#': 'D#', 'Mi': 'E',
      'Fa': 'F', 'Fa#': 'F#', 'Sol': 'G', 'Sol#': 'G#', 'La': 'A',
      'La#': 'A#', 'Si': 'B'
    };

    const mapping = targetNotation === 'DoReMi' ? toDoReMi : toCDE;

    const parts = chordName.split(" ");
    if (parts.length > 1) {
      const [note, ...qualityParts] = parts;
      const quality = qualityParts.join(" ");
      const displayNote = mapping[note] || note;
      return `${displayNote} ${quality}`;
    } else {
      return mapping[chordName] || chordName;
    }
  };

  const updateChordDisplayNames = (notation) => {
    const updatedChords = droppedChords.map(chordObj => {
      const newDisplayName = convertToDisplayNotation(chordObj.internalName, notation);
      return { ...chordObj, displayName: newDisplayName };
    });

    setDroppedChords(updatedChords);
  };

  const handleNotationChange = (newNotation) => {
    setNotationType(newNotation);
    const updatedChords = droppedChords.map(chordObj => {
      const newDisplayName = convertToDisplayNotation(chordObj.internalName, newNotation);
      return { ...chordObj, displayName: newDisplayName };
    });
    setDroppedChords(updatedChords);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const chordName = e.dataTransfer.getData('text');
    const isNewChord = e.dataTransfer.getData('isNewChord') === 'true';

    let internalChordName = notationType === 'CDE' ? convertToInternalNotation(chordName) : chordName;
    let displayChordName = notationType === 'DoReMi' ? chordName : convertToDisplayNotation(chordName);

    const chordObject = {
      internalName: internalChordName,
      displayName: displayChordName,
    };

    if (isNewChord) {
      const dropIndex = calculateSegmentBasedDropIndex(e.clientX);
      const newDroppedChords = [...droppedChords];
      newDroppedChords.splice(dropIndex, 0, chordObject);
      setDroppedChords(newDroppedChords);
    } else {
      const newDroppedChords = [...droppedChords];
      const draggedIndex = newDroppedChords.findIndex(chord => chord.internalName === chordObject.internalName);
      if (draggedIndex >= 0) {
        newDroppedChords.splice(draggedIndex, 1);
        const dropIndex = calculateSegmentBasedDropIndex(e.clientX);
        newDroppedChords.splice(dropIndex, 0, chordObject);
        setDroppedChords(newDroppedChords);
      }
    }
  };

  const handleSaveClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (saveMenu.visible) {
      setSaveMenu({
        ...saveMenu,
        visible: false,
      });
    } else {
      const rect = event.currentTarget.getBoundingClientRect();

      setSaveMenu({
        visible: true,
        x: rect.left,
        y: rect.bottom,
      });
    }
  };

  const closeSaveMenu = () => {
    setSaveMenu({ visible: false, x: 0, y: 0 });
  };

  const downloadMidi = async () => {
    const chordNames = droppedChords.map(chordObj => chordObj.internalName);
    const midiDataUri = await createMidiFile(chordNames);

    const element = document.createElement('a');
    element.setAttribute('href', midiDataUri);
    element.setAttribute('download', 'ChordProg.mid');

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const createMidiFile = async (chords) => {
    const { default: MidiWriter } = await import('midi-writer-js');
    let track = new MidiWriter.Track();

    chords.forEach(chord => {
      let midiNotes = convertChordToMidi(chord);
      let noteEvent = new MidiWriter.NoteEvent({ pitch: midiNotes, duration: '4' });
      track.addEvent(noteEvent);
    });

    let write = new MidiWriter.Writer(track);
    return write.dataUri();
  };

  const convertChordToMidi = (chord) => {
    let [rootNote, quality] = chord.split(" ");
    let midiNotes = [noteToMidi[rootNote]];
    let intervals = chordIntervals[quality];

    let currentNoteMidi = midiNoteToNumber(midiNotes[0]);

    intervals.forEach(interval => {
      currentNoteMidi += interval;
      midiNotes.push(midiNumberToNote(currentNoteMidi));
    });

    return midiNotes;
  };

  const midiNoteToNumber = (note) => {
    const noteToNumber = {
      "C": 0,
      "C#": 1,
      "D": 2,
      "D#": 3,
      "E": 4,
      "F": 5,
      "F#": 6,
      "G": 7,
      "G#": 8,
      "A": 9,
      "A#": 10,
      "B": 11
    };

    const baseMidi = 60;
    const noteLetter = note.substring(0, note.length - 1);
    const octave = parseInt(note[note.length - 1], 10);

    return baseMidi + noteToNumber[noteLetter] + 12 * (octave - 4);
  };

  const midiNumberToNote = (number) => {
    const numberToNote = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
    const octave = Math.floor(number / 12) - 1;
    const noteIndex = number % 12;

    return numberToNote[noteIndex] + octave;
  };

  function convertFloat32ToInt16(buffer) {
    let l = buffer.length;
    let buf = new Int16Array(l);
    while (l--) {
      buf[l] = Math.min(1, buffer[l]) * 0x7FFF;
    }
    return buf;
  }

  const handleDownloadMP3 = async () => {
    const { default: lamejs } = await import('lamejs');
    const audioContext = new AudioContext();
    const buffers = [];

    for (const chordObj of droppedChords) {
      const response = await fetch(getSoundFilePath(chordObj.internalName));
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      buffers.push(audioBuffer);
    }

    const concatenatedBuffer = concatenateAudioBuffers(buffers, audioContext);
    const mp3Data = encodeAudioBufferToMP3(concatenatedBuffer, lamejs);

    const blob = new Blob([new Uint8Array(mp3Data)], { type: 'audio/mp3' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ChordProg.mp3';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  function concatenateAudioBuffers(buffers, audioContext) {
    let totalLength = buffers.reduce((acc, buffer) => acc + buffer.length, 0);

    let concatenatedBuffer = audioContext.createBuffer(
      buffers[0].numberOfChannels, totalLength, buffers[0].sampleRate
    );

    let offset = 0;
    buffers.forEach(buffer => {
      for (let i = 0; i < buffer.numberOfChannels; i++) {
        concatenatedBuffer.getChannelData(i).set(buffer.getChannelData(i), offset);
      }
      offset += buffer.length;
    });

    return concatenatedBuffer;
  }

  function encodeAudioBufferToMP3(audioBuffer, lamejs) {
    let channels = audioBuffer.numberOfChannels;
    let sampleRate = audioBuffer.sampleRate;
    let kbps = 128;
    let mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps);
    let mp3Data = [];

    let samples = audioBuffer.length;
    let buffer = [];

    let leftChannel = convertFloat32ToInt16(audioBuffer.getChannelData(0));
    let rightChannel = audioBuffer.numberOfChannels > 1 ?
      convertFloat32ToInt16(audioBuffer.getChannelData(1)) :
      leftChannel;

    for (let i = 0; i < samples; i += 1152) {
      buffer = mp3encoder.encodeBuffer(leftChannel.subarray(i, i + 1152), rightChannel.subarray(i, i + 1152));
      if (buffer.length > 0) {
        mp3Data.push(buffer);
      }
    }

    buffer = mp3encoder.flush();
    if (buffer.length > 0) {
      mp3Data.push(buffer);
    }

    let mp3Buffer = new Uint8Array(mp3Data.reduce((acc, curr) => acc.concat(Array.from(curr)), []));
    return mp3Buffer;
  }

  const visualizeGuitarChords = () => {
    setGuitarChordsVisible((prevVisible) => !prevVisible);
    closeSaveMenu();
  };

  const updateGuitarChordImages = () => {
    const chordImages = droppedChords.map(chordObj => {
      const chordFileName = chordObj.internalName.replace(/\s+/g, '-').replace(/#/g, 'S') + '.png';
      return `/Chords-Guitar/${chordFileName}`;
    });
    setGuitarChordImages(chordImages);
  };

  useEffect(() => {
    if (guitarChordsVisible) {
      updateGuitarChordImages();
    } else {
      setGuitarChordImages([]);
    }
  }, [droppedChords, guitarChordsVisible]);

  const visualizePianoChords = () => {
    setPianoChordsVisible((prevVisible) => !prevVisible);
    closeSaveMenu();
  };

  const updatePianoChordImages = () => {
    const chordImages = droppedChords.map(chordObj => {
      const chordFileName = chordObj.internalName.replace(/\s+/g, '-').replace(/#/g, 'S') + '.png';
      return `/Chords-Piano/${chordFileName}`;
    });
    setPianoChordImages(chordImages);
  };

  
  useEffect(() => {
    if (pianoChordsVisible) {
      updatePianoChordImages();
    } else {
      setPianoChordImages([]);
    }
  }, [droppedChords, pianoChordsVisible]);

  const saveOptions = [
    { label: "Download MIDI", action: downloadMidi },
    { label: "Download MP3", action: handleDownloadMP3 },
    {
      label: "Chords for guitar",
      action: visualizeGuitarChords,
      check: guitarChordsVisible
    },
    {
      label: "Chords for piano",
      action: visualizePianoChords,
      check: pianoChordsVisible
    },
    { label: "Close", action: closeSaveMenu }
  ];

  const handleSaveMenuClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (saveMenu.visible) {
        closeSaveMenu();
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [saveMenu.visible]);

  const handleChordClick = (chord) => {
    setSelectedChords((chords) =>
      chords.includes(chord)
        ? chords.filter((c) => c !== chord)
        : chords.concat(chord)
    );
  };

  const handleScaleClick = (scale) => {
    setSelectedScales((scales) =>
      scales.includes(scale)
        ? scales.filter((s) => s !== scale)
        : scales.concat(scale)
    );
  };

  let incompatibleChords = selectedScales.length
    ? getIncompatibles(
      Object.keys(generatedChords),
      selectedScales,
      "chord",
      "some",
      generatedChords,
      generatedScales
    )
    : [];

  let incompatibleScales = selectedChords.length
    ? getIncompatibles(
      Object.keys(generatedScales),
      selectedChords,
      "scale",
      "some",
      generatedChords,
      generatedScales
    )
    : [];

  const maybeCompatibleChords = Object.keys(generatedChords).filter(
    (chord) => !incompatibleChords.includes(chord)
  );
  const maybeCompatibleScales = Object.keys(generatedScales).filter(
    (scale) => !incompatibleScales.includes(scale)
  );

  if (incompatibleScales.length) {
    incompatibleChords = incompatibleChords.concat(
      getIncompatibles(
        maybeCompatibleChords,
        maybeCompatibleScales,
        "chord",
        "every",
        generatedChords,
        generatedScales
      )
    );
  }

  if (incompatibleChords.length) {
    incompatibleScales = incompatibleScales.concat(
      getIncompatibles(
        maybeCompatibleScales,
        maybeCompatibleChords,
        "scale",
        "every",
        generatedChords,
        generatedScales
      )
    );
  }

  const generateRowCells = (type, rowQuality) => {
    return notes.map((note) => {
      const combination = `${note} ${rowQuality}`;
      const collection = type === "chord" ? selectedChords : selectedScales;
      let buttonColor;
      if (theme === 'dark') {
        buttonColor = "#353537";
      } else {
        buttonColor = "#FFFFFF";
      }

      const compatibleColor = theme === 'light' ? "#4CAF50" : "#11823b";
      const incompatibleColor = theme === 'light' ? "#F94449" : "#a70000";

      if (collection.includes(combination)) {
        buttonColor = compatibleColor;
      } else if ((type === "chord" && incompatibleChords.includes(combination)) || (type === "scale" && incompatibleScales.includes(combination))) {
        buttonColor = incompatibleColor;
      }

      const onClick = buttonColor !== incompatibleColor
        ? (type === "chord" ? () => handleChordClick(combination) : () => handleScaleClick(combination))
        : null;

      const draggable = type === "chord";
      const onContextMenu = (e) => handleRightClick(e, combination, type);

      const displayedNote = notationType === 'CDE' ? mapNotation(note) : note;

      return (
        <td
          key={note}
          onClick={onClick}
          onContextMenu={onContextMenu}
          style={{ backgroundColor: buttonColor, cursor: 'pointer' }}
          className={buttonColor === incompatibleColor ? "incompatible" : "draggable-chord"}
          draggable={draggable}
          onDragStart={draggable ? (e) => handleDragStart(e, `${displayedNote} ${rowQuality}`) : null}
        >
          {`${displayedNote} ${rowQuality}`}
        </td>
      );
    });
  };

  const generateTableRows = (type) => {
    const qualities = type === "chord" ? chordQualities : scaleQualities;
    return qualities.map((quality) => (
      <tr key={quality}>
        <th>{quality}</th>
        {generateRowCells(type, quality)}
      </tr>
    ));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (droppedChords.length > 0) {
        if (["Space", "KeyG", "KeyP", "Digit3"].includes(event.code)) {
          event.preventDefault();
        }

        if (event.code === "Space" && !isPlaying) {
          handlePlayClick();
        }

        if (event.code === "KeyG") {
          visualizeGuitarChords();
        }

        if (event.code === "KeyP") {
          visualizePianoChords();
        }

        if (event.ctrlKey && event.code === "KeyI") {
          downloadMidi();
        }

        if (event.ctrlKey && event.code === "Digit3") {
          handleDownloadMP3();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [droppedChords.length, isPlaying]);

  const isSaveDisabled = droppedChords.length === 0;

  const Loader = () => {
    return (
      <div className="loader-container">
        <h1 className="title">ChordProg</h1>
        <div className="loader"></div>
      </div>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const TutorialTab = ({ changeTab }) => {
    return (
      <div className="App">
        <div className="header">
          <div className="title-and-menu-icon">
            <h1 className="tutorial-title">ChordProg</h1>
          </div>
        </div>
        <div className='subtitle-container'><h2 className="subtitle">Discover the power of ChordProg by diving into our 3 minutes tutorial. Mastering its intricacies is key to unlocking a world of musical possibilities. Don't miss out on this essential step towards unleashing your creativity!</h2></div>
        <div className="tutorial-content">
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/1Eb4BxPdHS8?si=8_VQgtVfrM-FfzkT"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <a
            href="#main"
            className="use-chordprog"
            onClick={(e) => {
              e.preventDefault();
              changeTab('Main');
            }}
          >
            Continue to ChordProg
          </a>
        </div>
      </div>
    );
  };

  // Definición del componente ContextMenu
  const ContextMenu = ({ visible, position, onClose, children, theme }) => {
    if (!visible) return null;

    return (
      <ul
        className="context-menu"
        style={{
          top: position.top,
          left: position.left,
          position: 'fixed',
          backgroundColor: theme === 'dark' ? '#2c2c2c' : '#ffffff',
          color: theme === 'dark' ? '#BCBCBC' : '#2d2d2d',
          boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
          listStyleType: 'none',
          padding: '7.5px',
          margin: 0,
          zIndex: 1000,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </ul>
    );
  };

  return (
    <div className="App">
      {loading ? (
        <Loader />
      ) : (
        <div>
          {activeTab === 'Tutorial' ? (
            <TutorialTab changeTab={changeTab} />
          ) : (
            <>
              <div className="header">
                <div className="title-and-menu-icon">
                  <h1 className="title">ChordProg</h1>
                  {/* Nuevo contenedor para el ícono y el menú */}
                  <div className="menu-container" ref={menuIconRef}>
                    <div className="menu-icon" onClick={handleMenuClick}>☰</div>
                    {/* Menú desplegable */}
                    {isMenuVisible && (
                    <div className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                      <ul>
                        <li onClick={() => { changeTab('Tutorial'); setIsMenuVisible(false); }}>Tutorial</li>
                        <li>
                          <div className="dropdown-submenu">
                            <span
                              onClick={() => { handleNotationChange('DoReMi'); setIsMenuVisible(false); }}
                              style={{
                                color: notationType === 'DoReMi' ? '#297FBA' : '#BCBCAF',
                                cursor: 'pointer',
                                display: 'inline-block',
                              }}
                              className={`menu-option ${notationType === 'DoReMi' ? 'active-option' : ''}`}
                            >
                              Do
                            </span>
                            <span style={{ padding: '0 5px' }}>|</span>
                            <span
                              onClick={() => { handleNotationChange('CDE'); setIsMenuVisible(false); }}
                              style={{
                                color: notationType === 'CDE' ? '#297FBA' : '#BCBCAF',
                                cursor: 'pointer',
                                display: 'inline-block',
                              }}
                              className={`menu-option ${notationType === 'CDE' ? 'active-option' : ''}`}
                            >
                              C
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="dropdown-submenu">
                            <span
                              onClick={() => { toggleTheme('dark'); setIsMenuVisible(false); }}
                              style={{
                                color: theme === 'dark' ? '#297FBA' : '#BCBCAF',
                                cursor: 'pointer',
                                display: 'inline-block',
                              }}
                              className={`menu-option ${theme === 'dark' ? 'active-option' : ''}`}
                            >
                              Dark
                            </span>
                            <span style={{ padding: '0 5px' }}>|</span>
                            <span
                              onClick={() => { toggleTheme('light'); setIsMenuVisible(false); }}
                              style={{
                                color: theme === 'light' ? '#297FBA' : '#BCBCAF',
                                cursor: 'pointer',
                                display: 'inline-block',
                              }}
                              className={`menu-option ${theme === 'light' ? 'active-option' : ''}`}
                            >
                              Light
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="bpm-container">
                            <label htmlFor="bpm-input" className="bpm-label">BPM:</label>
                            <input
                              id="bpm-input"
                              className="bpm-input"
                              type="number"
                              min="60"
                              max="240"
                              value={bpm}
                              onChange={(e) => setBpm(e.target.value)}
                            />
                          </div>
                        </li>
                        <li onClick={() => { openContactPopup(); setIsMenuVisible(false); }}>Contact</li>
                        <li onClick={() => setIsMenuVisible(false)}>Close</li>
                      </ul>
                    </div>
                  )}
                  </div>
                </div>
              </div>

              {/* Tabla de acordes */}
              <div className="table-container">
                <table className="chord-table">
                  <thead>
                    <tr>
                      <th className="chords-cell">CHORDS</th>
                      {notes.map((note) => (
                        <th key={note}>{notationType === 'CDE' ? mapNotation(note) : note}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{generateTableRows("chord")}</tbody>
                </table>
              </div>

              {/* Barra de acordes y papelera */}
              <div className="bar-and-trash-container">
                <button
                  className="save-button"
                  disabled={isSaveDisabled}
                  onClick={handleSaveClick}
                >
                  💾
                </button>
                <div className="drop-bar" onDrop={handleDrop} onDragOver={handleDragOver}>
                  <button className="play-button" onClick={handlePlayClick}>Play</button>
                  {generateDroppedChords()}
                </div>
                <div className="trash-bin" onDrop={handleDropOnTrashBin} onDragOver={handleDragOver}>
                  🗑️
                </div>
              </div>

              {/* Visualización de acordes para guitarra */}
              {guitarChordsVisible && (
                <div className="guitar-chords-container">
                  {guitarChordImages.map((imgSrc, index) => {
                    const chordObj = droppedChords[index];
                    return (
                      <div key={index} className="chord-container">
                        <div className="chord-name">{chordObj.displayName}</div>
                        <img src={imgSrc} alt={`Guitar Chord: ${chordObj.displayName}`} loading="lazy" />
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Visualización de acordes para piano */}
              {pianoChordsVisible && (
                <div className="piano-chords-container">
                  {pianoChordImages.map((imgSrc, index) => {
                    const chordObj = droppedChords[index];
                    return (
                      <div key={index} className="chord-container">
                        <div className="chord-name">{chordObj.displayName}</div>
                        <img src={imgSrc} alt={`Piano Chord: ${chordObj.displayName}`} loading="lazy" />
                      </div>
                    );
                  })}
                </div>
              )}


              {/* Tabla de escalas */}
              <div className="table-container">
                <table className="scale-table">
                  <thead>
                    <tr>
                      <th className="scales-cell">SCALES</th>
                      {notes.map((note) => (
                        <th key={note}>{notationType === 'CDE' ? mapNotation(note) : note}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{generateTableRows("scale")}</tbody>
                </table>
              </div>

              {/* Menú contextual al hacer clic derecho */}
              {contextMenu.visible && (
                <ContextMenu
                  visible={contextMenu.visible}
                  position={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
                  onClose={closeContextMenu}
                  theme={theme}
                >
                  <li onClick={() => { selectedNote.play(); closeContextMenu(); }}>Play</li>
                  <li className="non-clickable">Notes: {selectedNote.notes}</li>
                  {selectedNote.type === "chord" && (
                    <li onClick={() => { selectedNote.addToBar(selectedNote.name); closeContextMenu(); }}>Add to bar</li>
                  )}
                  <li onClick={closeContextMenu}>Close</li>
                </ContextMenu>
              )}

              {/* Menú de guardado */}
              {saveMenu.visible && (
                <div
                  className="save-menu"
                  style={{ top: `${saveMenu.y}px`, left: `${saveMenu.x}px` }}
                  onClick={handleSaveMenuClick}
                >
                  <ul>
                    {saveOptions.map((option, index) => (
                      <li key={index} onClick={option.action}>
                        {option.label}{option.check ? " ✅" : ""}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Popup de contacto */}
              {isContactPopupVisible && (
                <div className="contact-popup-overlay" onClick={closeContactPopup}>
                  <div className="contact-popup" onClick={(e) => e.stopPropagation()}>
                    <div className="contact-popup-content">
                      <span className="close-popup" onClick={closeContactPopup}>×</span>
                      <h2>Contact</h2>
                      <p><br />
                        Thank you for reaching out! We're always here to help.
                        If you have any questions, suggestions, or need assistance, please don't hesitate to contact us.
                        You can reach us directly via email at:<br /><br />
                        contact@chordprog.com<br /><br />
                        We strive to respond to all inquiries as swiftly as possible and look forward to hearing from you.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
